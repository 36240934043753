import Separator from "@components/CustomTypography/Separator/Separator"
import {Translate} from "@components/Theme/Translate/Translate"
import React from "react"

import styles from "./ErrorMessage.module.scss"

type Props = {
	errors: Record<string, any>
	name: string
}

const ErrorMessage = (props: Props) => {
	return (
		<>
			{props.errors[props.name] &&
				props.errors[props.name] !== "first" && (
					<div className={styles.error}>
						<Translate capitalizeFirst>
							{props.errors[props.name]}
						</Translate>
					</div>
				)}
			{(!props.errors[props.name] ||
				props.errors[props.name] === "first") && (
				<Separator size={32} />
			)}
		</>
	)
}

export default ErrorMessage
