import Description from "@components/CustomTypography/Description/Description"
import {SeparatorType} from "@components/CustomTypography/Separator/Separator"
import {Translate} from "@components/Theme/Translate/Translate"
import {useValidation} from "@hooks/useValidation"
import {validate} from "@hooks/validate"
import React, {useEffect, useRef, useState} from "react"
import {CSSTransition} from "react-transition-group"

import ErrorMessage from "../ErrorMessage/ErrorMessage"
import {ValidationRule} from "../Validations"

import styles from "./Dropdown.module.scss"

type Option = {
	label: string
	value: string
}
type Props = {
	label: string
	name: string
	value: string
	disabled?: boolean
	placeholder?: string
	options: Option[]
	rules?: ValidationRule[]
	onChange: (event: string) => void
	onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
	formSubmitted?: boolean
	onError: (field: string, error: any) => void
	onDeleteError: (field: string) => void
	errors: Record<string, any>
	container?: SeparatorType
}

const Dropdown: React.FC<Props> = ({
	label,
	name,
	value,
	disabled = false,
	placeholder,
	options,
	rules = [],
	onChange,
	onBlur,
	formSubmitted,
	onError,
	onDeleteError,
	errors,
	container,
}) => {
	const [open, setOpen] = useState(false)
	const validation = useValidation(
		onDeleteError,
		onError,
		name,
		rules,
		value,
		formSubmitted,
	)
	const validationFunc = () =>
		validate(onDeleteError, onError, name, rules, value)

	const handleOptionClick = (optionValue: string) => {
		onChange(optionValue)
		validationFunc()
		setOpen(false)
	}

	const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
		if (onBlur) {
			onBlur(event)
		}
		validationFunc()
	}

	const dropdownRef = useRef<HTMLDivElement>(null)

	const handleDocumentClick = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node) &&
			open
		) {
			setOpen(false)
			validate(onDeleteError, onError, name, rules, value)
		}
	}

	useEffect(() => {
		if (open) {
			document.addEventListener("mousedown", handleDocumentClick)
		} else {
			document.removeEventListener("mousedown", handleDocumentClick)
		}

		return () => {
			document.removeEventListener("mousedown", handleDocumentClick)
		}
	}, [open])

	return (
		<div
			ref={dropdownRef}
			className={`${styles.inputContainer} ${container?.className}`}
		>
			<label className={styles.label} htmlFor={name}>
				<Translate capitalize>{label}</Translate>{" "}
				{validation.isRequired ? " *" : ""}
			</label>
			<div
				className={`${styles.customSelect}  ${
					open ? styles.open : styles.closed
				}`}
				id={name}
				onBlur={handleBlur}
				onClick={!disabled ? () => setOpen(!open) : () => {}}
			>
				<Description
					className={`${styles.selectedOption} ${
						errors[name] && errors[name] !== "first"
							? styles.inputError
							: ""
					} ${open ? styles.open : styles.closed} ${
						styles[disabled ? "disabled" : ""]
					}`}
					content={
						options?.find(
							(element: Option) => element?.value === value,
						)?.label
					}
					capitalize
				/>

				<CSSTransition
					classNames={{
						enter: styles.enter,
						enterActive: styles.enterActive,
						exit: styles.exit,
						exitActive: styles.exitActive,
					}}
					in={open}
					timeout={200}
					unmountOnExit
				>
					<div className={styles.options} onBlur={handleBlur}>
						{options.map((option) => (
							<Description
								key={option.value}
								className={`${styles.option} ${
									option.value === value
										? styles.selected
										: ""
								}`}
								content={option.label}
								capitalize
								onBlur={handleBlur}
								onClick={() => handleOptionClick(option.value)}
							/>
						))}
					</div>
				</CSSTransition>
			</div>
			<ErrorMessage errors={errors} name={name} />
		</div>
	)
}

export default Dropdown
