export type ValidationRule = {
	validate: (value: string | File | null) => boolean
	message: string
}

export const validate = (
	onDeleteError: (name: string) => void,
	onError: (name: string, message: string) => void,
	name: string,
	rules: ValidationRule[],
	value: string | File | null,
) => {
	for (const rule of rules) {
		if (!rule.validate(value)) {
			onError(name, rule.message)
			return
		}
	}

	onDeleteError(name)
}
