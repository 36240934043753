import unfetch from "isomorphic-unfetch"

export const postHubspotForm = async (
	hubspotPortalId: string,
	hubspotFormId: string,
	data: object,
): Promise<{status: number | string; result: any}> => {
	const response = await unfetch(
		`https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		},
	)

	const result = await response.json()
	return {
		status: response.status,
		result,
	}
}
