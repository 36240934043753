import {requiredRule} from "@components/BasicComponents/Forms/Validations"
import {useEffect, useState} from "react"

import {ValidationRule, validate} from "./validate"

export const useValidation = (
	onDeleteError: (name: string) => void,
	onError: (name: string, message: string) => void,
	name: string,
	rules: ValidationRule[],
	value: string | File | null,
	formSubmitted?: boolean,
) => {
	const [firstRender, setFirstRender] = useState(true)
	const isRequired = rules.find(
		(element) => element.validate === requiredRule.validate,
	)

	useEffect(() => {
		if (rules.length > 0 && typeof value !== "undefined" && !firstRender) {
			validate(onDeleteError, onError, name, rules, value)
		}

		if (firstRender && isRequired) {
			onError(name, "first")
		}
	}, [value, formSubmitted])

	useEffect(() => {
		setFirstRender(false)
	}, [value, formSubmitted])

	return {isRequired}
}
