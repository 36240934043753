import {SeparatorType} from "@components/CustomTypography/Separator/Separator"
import {Translate} from "@components/Theme/Translate/Translate"
import {translateWithRouter} from "@helpers/translate"
import {useValidation} from "@hooks/useValidation"
import {validate} from "@hooks/validate"
import React from "react"

import ErrorMessage from "../ErrorMessage/ErrorMessage"
import {ValidationRule} from "../Validations"

import styles from "./Input.module.scss"

type Props = {
	container?: SeparatorType
	label: string
	disabled?: boolean
	name: string
	value: string
	placeholder?: string
	type?: string
	rules?: ValidationRule[]
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
	formSubmitted?: boolean
	onError: (field: string, error: any) => void
	onDeleteError: (field: string) => void
	errors: Record<string, any>
}

const Input: React.FC<Props> = ({
	label,
	name,
	value,
	placeholder,
	type = "text",
	disabled = false,
	rules = [],
	onChange,
	onBlur,
	formSubmitted,
	errors,
	onError,
	onDeleteError,
	container,
}) => {
	const validation = useValidation(
		onDeleteError,
		onError,
		name,
		rules,
		value,
		formSubmitted,
	)

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		if (onBlur) {
			onBlur(event)
		}
		validate(onDeleteError, onError, name, rules, value)
	}

	return (
		<div
			className={`${styles.inputContainer} ${container?.className}`}
			id={name}
		>
			<label className={styles.label} htmlFor={name}>
				<Translate capitalize>{label}</Translate>{" "}
				{validation.isRequired ? " *" : ""}
			</label>
			<input
				className={
					errors[name] && errors[name] !== "first"
						? styles.inputError
						: styles.input
				}
				disabled={disabled}
				id={name}
				name={name}
				placeholder={translateWithRouter(placeholder || "")}
				type={type}
				value={value}
				onBlur={handleBlur}
				onChange={onChange}
			/>
			<ErrorMessage errors={errors} name={name} />
		</div>
	)
}

export default Input
