import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import Separator, {
	SeparatorType,
} from "@components/CustomTypography/Separator/Separator"
import ButtonComponent from "@components/Theme/Button/Button"
import {postHubspotForm} from "@hooks/HubspotForm"
import {getLink} from "@hooks/PagesData/getLink"
import Cookies from "js-cookie"
import Link from "next/link"
import {useRouter} from "next/router"
import React, {useEffect, useState} from "react"

import Dropdown from "../Dropdown/Dropdown"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Input from "../Input/Input"
import TextInput from "../TextInput/TextInput"
import ThankYou from "../ThankYou/ThankYou"
import {
	maxLengthRule,
	minLengthRule,
	numericOnlyRule,
	prepareErrorResponse,
	requiredRule,
} from "../Validations"

import styles from "./ContactUsForm.module.scss"

export enum FormIdType {
	STUDIO_STARTER = "STUDIO_STARTER",
	STUDIO_TEAM = "STUDIO_TEAM",
	STUDIO_BUSINESS = "STUDIO_BUSINESS",
	STUDIO_ENTERPRISE = "STUDIO_ENTERPRISE",
	ONE_DEFAULT = "ONE_DEFAULT",
	STUDIO_DEFAULT = "STUDIO_DEFAULT",
}

const ContactUsForm = (props: {
	form?: FormIdType
	container?: SeparatorType
	button?: string
}): JSX.Element => {
	let formId: string | undefined
	switch (props.form) {
		case FormIdType.STUDIO_STARTER:
			formId = process.env.STUDIO_STARTER
			break
		case FormIdType.STUDIO_TEAM:
			formId = process.env.STUDIO_TEAM
			break
		case FormIdType.STUDIO_BUSINESS:
			formId = process.env.STUDIO_BUSINESS
			break
		case FormIdType.STUDIO_ENTERPRISE:
			formId = process.env.STUDIO_ENTERPRISE
			break
		case FormIdType.ONE_DEFAULT:
			formId = process.env.ONE_DEFAULT
			break
		case FormIdType.STUDIO_DEFAULT:
			formId = process.env.STUDIO_DEFAULT
			break
		default:
			formId = process.env.STUDIO_DEFAULT
			break
	}
	const realFormId = typeof formId !== "undefined" ? formId : ""

	const [loading, setLoading] = useState(false)
	const [serverError, setServerError] = useState(false)
	const [firstname, setFirstname] = useState("")
	const [lastname, setLastname] = useState("")
	const [company, setCompany] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [message, setMessage] = useState("")
	const [formSubmitted, setFormSubmitted] = useState(false)
	const [formSuccess, setFormSuccess] = useState(false)
	const [country, setCountry] = useState("")
	const [currentUrl, setCurrentUrl] = useState("")
	const [errors, setErrors] = useState<{
		[key: string]: any
	}>({firstRender: true})

	const router = useRouter()

	useEffect(() => {
		const domain = window.location.origin
		const path = router.pathname
		setCurrentUrl(`${domain}${path}`)
	}, [router.asPath])

	const handleError = (field: string, error: any) => {
		setErrors((prevErrors) => {
			if (
				Object.prototype.hasOwnProperty.call(prevErrors, "firstRender")
			) {
				delete prevErrors.firstRender
			}

			return {
				...prevErrors,
				[field]: error,
			}
		})
	}

	const handleDeleteError = (fieldName: string): void => {
		setErrors((prevErrors) => {
			const updatedErrors = Object.fromEntries(
				Object.entries(prevErrors).filter(([key]) => key !== fieldName),
			)

			if (
				Object.prototype.hasOwnProperty.call(
					updatedErrors,
					"firstRender",
				)
			) {
				delete updatedErrors.firstRender
			}

			return updatedErrors
		})
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setFormSubmitted(true)
	}

	const cookies = Cookies.get()

	const context = cookies?.hubspotutk
		? {
				hutk: cookies.hubspotutk,
				pageUri: currentUrl,
				pageName: currentUrl,
		  }
		: {
				pageUri: currentUrl,
				pageName: currentUrl,
		  }

	const sendData = async () => {
		try {
			setServerError(false)
			setLoading(true)

			await postHubspotForm(
				process.env.HUBSPOT_PORTAL_ID
					? process.env.HUBSPOT_PORTAL_ID
					: "",
				realFormId,
				{
					context,
					fields: [
						{
							name: "firstname",
							value: firstname,
						},
						{
							name: "lastname",
							value: lastname,
						},
						{
							name: "email",
							value: email,
						},
						{
							name: "company",
							value: company,
						},
						{
							name: "country",
							value: country,
						},
						{
							name: "country_",
							value: country,
						},
						{
							name: "contact_country",
							value: country,
						},
						{
							name: "mobilephone",
							value: phone,
						},
						{
							name: "phone",
							value: phone,
						},
						{
							name: "message",
							value: message,
						},
					],
					skipValidation: false,
				},
			)
				.then((result) => {
					if (result.status === 200) {
						setFormSuccess(true)
					}
					if (result.status === 400) {
						setFormSubmitted(false)
						setErrors({
							...prepareErrorResponse(result.result).fields,
						})
					}
				})
				.catch(() => {
					setServerError(true)
				})
				.finally(() => {
					setLoading(false)
				})
		} catch (error) {
			setFormSubmitted(false)
		}
	}

	useEffect(() => {
		if (Object.keys(errors).length > 0 && formSubmitted) {
			setFormSubmitted(false)
		}
		if (formSubmitted && Object.keys(errors).length <= 0) {
			void sendData()
		}
	}, [formSubmitted])

	const handleDropdownChange = (event: string) => {
		setCountry(event)
	}

	const options = [
		{label: "Select an option", value: ""},
		{label: "Finland", value: "Finland"},
		{label: "Sweden", value: "Sweden"},
		{label: "Denmark", value: "Denmark"},
		{label: "Norway", value: "Norway"},
		{label: "Netherlands", value: "Netherlands"},
		{label: "Germany", value: "Germany"},
		{label: "Iceland", value: "Iceland"},
		{label: "United Kingdom", value: "United Kingdom"},
		{label: "Other", value: "Other"},
	]

	const container = {
		className: styles.doubleInput,
	}
	return (
		<Separator withoutMeasure {...props.container}>
			{!formSuccess && (
				<form
					className={`${styles.formContainer} `}
					onSubmit={handleSubmit}
				>
					<Separator
						className={styles.doubleInputWrapper}
						type={"padding"}
						withoutMeasure
					>
						<Input
							container={container}
							disabled={loading}
							errors={errors}
							formSubmitted={formSubmitted}
							label={"First Name "}
							name={"firstname"}
							rules={[
								requiredRule,
								minLengthRule(2),
								maxLengthRule(225),
							]}
							value={firstname}
							onChange={(event) =>
								setFirstname(event.target.value)
							}
							onDeleteError={handleDeleteError}
							onError={handleError}
						/>
						<Input
							container={container}
							disabled={loading}
							errors={errors}
							formSubmitted={formSubmitted}
							label={"Last Name "}
							name={"lastname"}
							rules={[
								requiredRule,
								minLengthRule(2),
								maxLengthRule(225),
							]}
							value={lastname}
							onChange={(event) =>
								setLastname(event.target.value)
							}
							onDeleteError={handleDeleteError}
							onError={handleError}
						/>
					</Separator>

					<Separator
						className={styles.doubleInputWrapper}
						type={"padding"}
						withoutMeasure
					>
						<Input
							container={container}
							disabled={loading}
							errors={errors}
							formSubmitted={formSubmitted}
							label={"Email "}
							name={"email"}
							rules={[
								requiredRule,
								minLengthRule(2),
								maxLengthRule(225),
							]}
							type={"email"}
							value={email}
							onChange={(event) => setEmail(event.target.value)}
							onDeleteError={handleDeleteError}
							onError={handleError}
						/>

						<Input
							container={container}
							disabled={loading}
							errors={errors}
							formSubmitted={formSubmitted}
							label={"Phone "}
							name={"mobilephone"}
							rules={[
								requiredRule,
								numericOnlyRule,
								minLengthRule(2),
								maxLengthRule(225),
							]}
							type={"phone"}
							value={phone}
							onChange={(event) => setPhone(event.target.value)}
							onDeleteError={handleDeleteError}
							onError={handleError}
						/>
					</Separator>

					<Separator
						className={styles.doubleInputWrapper}
						type={"padding"}
						withoutMeasure
					>
						<Input
							container={container}
							disabled={loading}
							errors={errors}
							formSubmitted={formSubmitted}
							label={"Company/Organisation "}
							name={"company"}
							rules={[
								requiredRule,
								minLengthRule(2),
								maxLengthRule(225),
							]}
							value={company}
							onChange={(event) => setCompany(event.target.value)}
							onDeleteError={handleDeleteError}
							onError={handleError}
						/>
						<Dropdown
							container={container}
							disabled={loading}
							errors={errors}
							formSubmitted={formSubmitted}
							label={"Country "}
							name={"country"}
							options={options}
							placeholder={"Please select"}
							rules={[requiredRule]}
							value={country}
							onChange={handleDropdownChange}
							onDeleteError={handleDeleteError}
							onError={handleError}
						/>
					</Separator>
					<TextInput
						disabled={loading}
						errors={errors}
						formSubmitted={formSubmitted}
						label={"Message "}
						name={"message"}
						placeholder={"Start a conversation with us..."}
						rules={[maxLengthRule(1500)]}
						value={message}
						onChange={(event) => setMessage(event.target.value)}
						onDeleteError={handleDeleteError}
						onError={handleError}
					/>
					<Paragraph className={styles.privacyText} size={"XS"}>
						By submitting my information I agree to the terms and
						conditions described in the{" "}
						<Link
							href={getLink("privacyPolicy")}
							rel={"noreferrer"}
						>
							privacy statement
						</Link>{" "}
						regarding the use of my personal data and the use of
						cookies.
					</Paragraph>
					<div className={styles.buttonContainer}>
						<ButtonComponent
							action={"submit"}
							buttonType={"primary"}
							className={styles.button}
							disabled={loading}
							size={"large"}
						>
							{loading
								? "Sending..."
								: props.button
								? props.button
								: "Book a Demo"}
						</ButtonComponent>
					</div>
				</form>
			)}
			{serverError && (
				<ErrorMessage
					errors={{
						server: "Something went wrong. Please, try again.",
					}}
					name={"server"}
				/>
			)}
			{formSuccess && (
				<>
					<ThankYou name={firstname} />
				</>
			)}
		</Separator>
	)
}

export default ContactUsForm
