import React, {useEffect, useState} from "react"

import styles from "./ThankYou.module.scss"

interface ThankYouProps {
	name: string
}

const ThankYou: React.FC<ThankYouProps> = ({name}) => {
	const [show, setShow] = useState(false)

	useEffect(() => {
		setShow(true)
	}, [])

	return (
		<div
			className={
				show ? styles.wrapper : `${styles.wrapper} ${styles.hide}`
			}
		>
			<div className={styles.container}>
				<h2 className={styles.title}>Thank You, {name}!</h2>
				<p className={styles.message}>
					We have received your submission and will get back to you
					shortly.
				</p>
			</div>
		</div>
	)
}

export default ThankYou
