import {Translate} from "@components/Theme/Translate/Translate"
import {translateWithRouter} from "@helpers/translate"
import {useValidation} from "@hooks/useValidation"
import {validate} from "@hooks/validate"
import React from "react"

import ErrorMessage from "../ErrorMessage/ErrorMessage"
import {ValidationRule} from "../Validations"

import styles from "./TextInput.module.scss"

type Props = {
	label: string
	name: string
	value: string
	disabled?: boolean
	placeholder?: string
	rules?: ValidationRule[]
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
	formSubmitted?: boolean
	onError: (field: string, error: any) => void
	onDeleteError: (field: string) => void
	errors: Record<string, any>
}

const TextInput: React.FC<Props> = ({
	label,
	name,
	value,
	placeholder,
	rules = [],
	onChange,
	disabled = false,
	onBlur,
	formSubmitted,
	onError,
	errors,
	onDeleteError,
}) => {
	const validation = useValidation(
		onDeleteError,
		onError,
		name,
		rules,
		value,
		formSubmitted,
	)

	const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		if (onBlur) {
			onBlur(event)
		}
		validate(onDeleteError, onError, name, rules, value)
	}

	return (
		<div className={styles.inputContainer}>
			<label className={styles.label} htmlFor={name}>
				<Translate capitalize>{label}</Translate>{" "}
				{validation.isRequired ? " *" : ""}
			</label>
			<textarea
				className={
					errors[name] && errors[name] !== "first"
						? styles.inputError
						: styles.input
				}
				disabled={disabled}
				id={name}
				name={name}
				placeholder={translateWithRouter(placeholder || "")}
				value={value}
				onBlur={handleBlur}
				onChange={onChange}
			/>
			<ErrorMessage errors={errors} name={name} />
		</div>
	)
}
export default TextInput
